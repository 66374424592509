import { render, staticRenderFns } from "./greenHospitaltwoDetail.vue?vue&type=template&id=5fc93ad2&scoped=true"
import script from "./greenHospitaltwoDetail.vue?vue&type=script&lang=js"
export * from "./greenHospitaltwoDetail.vue?vue&type=script&lang=js"
import style0 from "./greenHospitaltwoDetail.vue?vue&type=style&index=0&id=5fc93ad2&prod&scoped=true&lang=css"
import style1 from "./greenHospitaltwoDetail.vue?vue&type=style&index=1&id=5fc93ad2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc93ad2",
  null
  
)

export default component.exports