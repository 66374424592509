import { render, staticRenderFns } from "./basic_user.vue?vue&type=template&id=2e89a418&scoped=true"
import script from "./basic_user.vue?vue&type=script&lang=js"
export * from "./basic_user.vue?vue&type=script&lang=js"
import style0 from "./basic_user.vue?vue&type=style&index=0&id=2e89a418&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e89a418",
  null
  
)

export default component.exports