<template>
  <!-- <el-dialog
    :visible.sync="visible"
    :title="$t('update')"
    :close-on-click-modal="false"
    :before-close="resetData"
    :close-on-press-escape="false"
    width="80%"
  > -->
  <div>
    <div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>服务单</span>
          <!-- <span></span> -->
          <span v-if="showCancelButton">
            <el-button
              type="danger"
              style="float: right; padding: 5px 5px; margin-right: 10px"
              @click="cancelServiceOrder"
              v-if="$hasPermission('miaoins')"
              >取消工单</el-button
            >
            <el-button
              type="primary"
              style="float: right; padding: 5px 5px; margin-right: 20px"
              @click="ServiceOrder"
              v-if="$hasPermission('miaoins')"
              >服务单完成</el-button
            >
          </span>
        </div>
        <div>
          <el-form
            :model="dataForm"
            :inline="true"
            :rules="dataRule"
            disabled
            ref="dataForm"
            :label-width="$i18n.locale === 'en-US' ? '130px' : '90px'"
          >
            <el-form-item label="用户权益id" prop="userRightId">
              <el-input
                readonly
                v-model="dataForm.userRightId"
                placeholder="用户权益表id"
              ></el-input>
            </el-form-item>
            <el-form-item label="权益代码" prop="rightCode">
              <el-input
                readonly
                v-model="dataForm.rightCode"
                placeholder="权益代码"
              ></el-input>
            </el-form-item>
            <el-form-item label="权益名称" prop="rightName">
              <div class="rightNameCon">
                <el-input
                  readonly
                  v-model="dataForm.rightName"
                  placeholder="权益名称"
                ></el-input>
              </div>
            </el-form-item>
            <el-popover
              :disabled="false"
              placement="bottom"
              width="600"
              trigger="hover"
              :content="dataForm.rightDesc"
            >
              <img
                class="tiShiImg"
                slot="reference"
                src="../../../assets/img/tishi.png"
              />
            </el-popover>
            <el-form-item label="权益别名" prop="aliasRightName">
              <el-input
                readonly
                v-model="dataForm.aliasRightName"
                placeholder="权益包名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="权益包名称" prop="packageName">
              <el-input
                readonly
                v-model="dataForm.packageName"
                placeholder="权益包名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务实体id" prop="entityId">
              <el-input
                readonly
                v-model="dataForm.entityId"
                placeholder="业务实体id"
              ></el-input>
            </el-form-item>
            <el-form-item label="工单名称" prop="ticketsName">
              <el-input
                readonly
                v-model="dataForm.ticketsName"
                placeholder="工单名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="工单号" prop="ticketsCode">
              <el-input
                readonly
                v-model="dataForm.ticketsCode"
                placeholder="工单号"
              ></el-input>
            </el-form-item>
            <el-form-item label="工单详情" prop="ticketsDesc">
              <el-input
                readonly
                v-model="dataForm.ticketsDesc"
                placeholder="工单详情"
              ></el-input>
            </el-form-item>
            <el-form-item label="工单状态" prop="status">
              <el-select
                disabled
                v-model="dataForm.status"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div @click="goPackageFaq()" class="FAQContent">
          <img src="../../../assets/img/FAQ.png" alt="" />
          <span class="FAQ">查看产品FAQ</span>
        </div>
      </el-card>
    </div>
    <div v-if="showGreen">
      <div style="margin-top: 15px">
        <basicUser ref="basicUser" @fatherMethod="fatherMethod"></basicUser>
      </div>
      <div style="margin-top: 15px">
        <basicApplication
          ref="basicApplication"
          @fatherMethod="fatherMethod"
        ></basicApplication>
      </div>
      <div style="margin-top: 15px" v-if="showBasicImageInformation">
        <basicImageInformation
          ref="basicImageInformation"
          @fatherMethod="fatherMethod"
        ></basicImageInformation>
      </div>
    </div>
    <div style="margin-top: 15px">
      <greenServiceLog
        ref="serviceLog"
        @fatherMethod="fatherMethod"
      ></greenServiceLog>
    </div>
    <!-- <div>sadfasdfa</div> -->
    <div style="margin-top: 15px" class="buttonCon">
      <div v-if="greenStatus === 7">
        <el-button
          type="primary"
          v-if="$hasPermission('miaoins')"
          @click="updateStatus(0)"
          >受理通过</el-button
        >
      </div>
      <div v-if="greenStatus === 0">
        <el-button
          type="primary"
          v-if="$hasPermission('miaoins')"
          @click="updateStatus(2)"
          >初审通过</el-button
        >
        <el-button
          type="primary"
          v-if="$hasPermission('miaoins')"
          @click="updateStatus(1)"
          >初审不通过</el-button
        >
      </div>
      <div v-if="greenStatus === 2">
        <el-button
          type="primary"
          v-if="$hasPermission('miaoins')"
          @click="updateStatus(3)"
          >需求确认</el-button
        >
      </div>
      <div v-if="greenStatus === 3">
        <el-button
          type="primary"
          v-if="$hasPermission('greenTPA') || $hasPermission('miaoins')"
          @click="updateStatus(4)"
          >预约成功</el-button
        >
      </div>
      <div v-if="greenStatus === 4">
        <el-button
          type="primary"
          v-if="$hasPermission('greenTPA') || $hasPermission('miaoins')"
          @click="updateStatus(6)"
          >结案</el-button
        >
      </div>
    </div>
  </div>
  <!-- </el-dialog> -->
</template>

<script>
import basicUser from '../../../components/greenHospitaltwoDetail/basic_user';
import basicApplication from '../../../components/greenHospitaltwoDetail/basic_application';
import basicImageInformation from '../../../components/greenHospitaltwoDetail/basic_image_information';
import greenServiceLog from '../../../components/GreenChannel/green_Service_Log';
export default {
  inject: ['refresh'],
  data() {
    return {
      visible: false,
      showCancelButton: true,
      showBasicImageInformation: true,
      dataForm: {
        id: '',
        userRightId: '',
        rightCode: '',
        rightName: '',
        entityId: '',
        ticketsName: '',
        ticketsCode: '',
        ticketsDesc: '',
        status: '',
        creator: '',
        createDate: '',
        updater: '',
        updateDate: '',
      },
      ticketId: '',
      statusOptions: [
        { id: 0, name: '已取消' },
        { id: 1, name: '进行中' },
        { id: 2, name: '已完成' },
      ],
      showGreen: true,
      showBigTree: false,
      url: '',
      num: 0,
      fileList: [],
      greenStatus: '',
      greenId: '',
      greenBasicInformation: '上海',
      serveOrderId: '',
      isSecret: 1,
      packageFAQUrl: '',
    };
  },
  computed: {
    dataRule() {
      return {
        userRightId: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        rightCode: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        rightName: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        entityId: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        ticketsName: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        ticketsCode: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        ticketsDesc: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        updater: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        updateDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  components: {
    basicUser,
    basicApplication,
    basicImageInformation,
    greenServiceLog,
  },
  created() {
    this.init();
    // this.getInfo();
  },
  methods: {
    init() {
      this.url = `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`;
      this.num = 0;
      this.fileList = [];
      if (this.$route.query.id) {
        this.initDialog(this.$route.query.id);
      }
    },
    queryUpdateStatus() {
      let params = {
        id: this.greenId,
      };
      this.$http
        .post(
          `/healthbusiness/health-green-channel/selectBigTreeOrderInfo`,
          params
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          // console.log(res);
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              // location.reload();
            },
          });
        })
        .catch(() => {});
    },
    initDialog(event) {
      console.log(event);
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (event) {
          this.serveOrderId = event;
          this.getInfo();
        }
      });
    },
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
    fatherMethod() {
      this.isSecret = 0;
      this.getInfo();
    },
    // 获取信息
    getInfo() {
      let params = {
        id: this.serveOrderId,
        isSecret: this.isSecret,
      };
      this.$http
        .post(`/healthbusiness/health-tickets/getTicketsDetails`, params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          // console.log('我的数据', res.data);
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          };
          this.ticketId = res.data.id;
          this.packageFAQUrl = res.data.packageFAQUrl;
          // this.dataForm.rightDesc = this.showHtml(this.dataForm.rightDesc);
          console.log('我的数据', res.data);
          if (res.data.status === 2 || res.data.status === 0) {
            this.showCancelButton = false;
          } else {
            this.showCancelButton = true;
          }
          if (res.data.entityDetails) {
            this.greenStatus = res.data.entityDetails.greenStatus;
          } else {
            this.greenStatus = '';
          }
          this.greenId = res.data.entityId;

          if (res.data.rightId == '47') {
            this.showBasicImageInformation = true;
          } else {
            this.showBasicImageInformation = false;
          }

          // 更新子组件
          this.$refs.basicUser.greenFun(res.data);
          this.$refs.basicApplication.greenFun(res.data);
          this.$refs.basicImageInformation.greenFun3(res.data);
          this.$refs.serviceLog.greenFunservelog(res.data);
        })
        .catch(() => {});
    },
    // 取消工单
    cancelServiceOrder() {
      let that = this;
      that
        .$confirm('此操作将会进行取消工单操作, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          // console.log('却倾');
          that.cancelService();
        })
        .catch(() => {
          that.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    cancelService() {
      let params = {
        id: this.serveOrderId,
      };
      this.$http
        .post(`/healthbusiness/health-tickets/cancel`, params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              // this.visible = false;
              this.refresh();
              this.$emit('getMethod');
            },
          });
        })
        .catch(() => {});
    },
    // 取消工单
    ServiceOrder() {
      this.$confirm('此操作将完成服务单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.updateServiceOrder();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 保存修改
    updateServiceOrder() {
      let params = {
        id: this.serveOrderId,
      };
      this.$http
        .post('/healthbusiness/health-tickets/finished', params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              // this.visible = false;
              this.refresh();
              this.$emit('getMethod');
            },
          });
        })
        .catch(() => {});
    },
    updateStatus(status) {
      let params = {
        id: this.greenId,
        ticketId: this.ticketId,
        greenStatus: status,
      };
      this.$http
        .post('/healthbusiness/health-green-channel/update', params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.getInfo();
            },
          });
        })
        .catch(() => {});
    },
    showHtml(str) {
      return str.replace(/<\/?p[^>]*>/gi, '');
    },
    goPackageFaq() {
      let packageFAQUrl = this.packageFAQUrl;
      if (!packageFAQUrl) {
        return this.$message.error('当前产品暂无FAQ');
      } else {
        window.open(packageFAQUrl, '_blank');
      }
    },
  },
};
</script>
<style scoped>
.uploadCon {
  display: flex;
  align-items: center;
}
.buttonCon {
  margin: 0 auto;
  text-align: center;
}
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.tiShiImg {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  /* margin-left: -10px; */
}
.rightNameCon {
  /* width: 380px; */
  display: flex;
  position: relative;
}
.rightNameCon div:nth-child(2) {
  width: 20px;
}
.hint {
  width: 700px;
  line-height: 20px;
  font-size: 10px;
  padding: 10px;
  /* padding-left: 100px; */
  display: none;
}
.text {
  width: 700px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px #dbdada;
  background-color: #fff;
  z-index: 999999;
  position: absolute;
  left: 100px;
  padding-left: 10px;
  padding-bottom: 20px;
}
.hover:hover .hint {
  display: block;
  width: 700px;
  height: 10px;
  position: absolute;
  left: -100px;
}
/deep/.el-input {
  color: #000 !;
}
/deep/.el-input.is-disabled .el-input__inner {
  color: #606266;
}
</style>
<style lang="scss" scoped>
.box-card {
  position: relative;
  .FAQContent {
    position: absolute;
    right: 10px;
    bottom: 10px;
    img {
      width: 16px;
      height: 16px;
    }
    .FAQ {
      font-size: 12px;
    }
  }
}
</style>
